@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none; }

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none; }

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box; }
  @media (max-width: 1124px) {
    html {
      font-size: 56%; } }
  @media (max-width: 768px) {
    html {
      font-size: 62.5%; } }

body {
  color: #333;
  letter-spacing: 0.02em;
  line-height: 1.5; }

main {
  display: block; }

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer; }

img {
  max-width: 100%;
  width: 100%;
  line-height: 1;
  vertical-align: bottom; }

button {
  outline: none; }

input {
  outline: none; }

textarea {
  outline: none;
  resize: none; }

.wrap {
  position: relative;
  overflow: hidden; }

/* Components */
.text-link {
  background-image: linear-gradient(90deg, #929ecc, #929ecc);
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: 0 1px;
  transition: all 0.3s; }
  .text-link:hover {
    background-size: 100% 1px; }

.text-break {
  display: inline-block; }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111b47;
  border-radius: 2px;
  color: #fff;
  font-weight: 500;
  padding: 0.2em 0;
  transition: all 0.3s;
  border: 2px solid #111b47; }
  @media (max-width: 480px) {
    .btn {
      font-size: 1.4rem; } }
  .btn:hover {
    background-color: transparent;
    color: #37447e; }
  .btn--reverse {
    border: 2px solid #111b47;
    color: #111b47;
    background-color: transparent; }
    .btn--reverse:hover {
      background-color: #111b47;
      color: #fff; }

.list__item {
  font-size: 1.4rem;
  line-height: 1.7; }
  .list__item::before {
    content: "・"; }

.container {
  width: 100%;
  max-width: 116rem;
  padding: 0 3rem;
  margin: 0 auto; }
  @media (max-width: 768px) {
    .container {
      padding: 0 6%; } }

.section__title {
  font-size: 3.6rem;
  font-weight: 500;
  color: #37447e;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
  @media (max-width: 768px) {
    .section__title {
      font-size: 3rem; } }
  @media (max-width: 480px) {
    .section__title {
      font-size: 2.4rem;
      text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); } }

.hamburger-menu {
  width: 100%;
  height: 100%;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  transform: translateX(100%);
  transition: all 0.6s;
  cursor: pointer; }
  .hamburger-menu.is-active {
    transform: translateX(0); }
  .hamburger-menu__content {
    position: absolute;
    right: 0;
    top: 0;
    width: 28rem;
    height: 100%;
    background-color: #929ecc;
    cursor: auto; }
  .hamburger-menu__close {
    position: absolute;
    right: 3rem;
    top: 2rem;
    width: 3rem;
    height: 3rem;
    transform: rotate(45deg); }
    @media (max-width: 480px) {
      .hamburger-menu__close {
        width: 2.4rem;
        height: 2.4rem;
        right: 6%;
        top: 1.6rem; } }
    .hamburger-menu__close::before, .hamburger-menu__close::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: #fff;
      border-radius: 10px; }
    .hamburger-menu__close::before {
      height: 2px;
      width: 100%; }
    .hamburger-menu__close::after {
      height: 100%;
      width: 2px; }
  .hamburger-menu__list {
    padding: 6rem 2rem 2rem; }
    @media (max-width: 480px) {
      .hamburger-menu__list {
        padding: 5rem 6% 2rem; } }
  .hamburger-menu__item:not(:first-child) {
    border-top: 1px solid #c2cdf6; }
  .hamburger-menu__item-btn {
    display: flex;
    align-items: center; }
  .hamburger-menu__link {
    display: inline-block;
    font-size: 1.4rem;
    width: 100%;
    color: #fff;
    line-height: 1.7;
    padding: 1.2rem 0; }
    @media (max-width: 480px) {
      .hamburger-menu__link {
        padding: 1rem 0; } }
  .hamburger-menu__link-btn {
    width: 11rem;
    padding: 0;
    text-align: center;
    margin: 1.2rem 0; }

.hamburger-btn {
  display: none; }
  @media (max-width: 768px) {
    .hamburger-btn {
      position: absolute;
      right: 6%;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      width: 3rem;
      height: 2rem;
      z-index: 100; } }
  @media (max-width: 480px) {
    .hamburger-btn {
      width: 2.25rem;
      height: 1.5rem; } }
  .hamburger-btn::before, .hamburger-btn::after {
    content: ""; }
  .hamburger-btn span, .hamburger-btn::before, .hamburger-btn::after {
    width: 100%;
    height: 2px;
    background-color: #37447e;
    display: block;
    position: absolute;
    border-radius: 10px;
    transition: all 0.3s; }
  .hamburger-btn span {
    top: 0;
    bottom: 0;
    margin: auto 0; }
  .hamburger-btn::before {
    top: 0; }
  .hamburger-btn::after {
    bottom: 0; }
  .hamburger-btn.is-active span, .hamburger-btn.is-active::before, .hamburger-btn.is-active::after {
    background-color: #fff; }
  .hamburger-btn:hover::before {
    transform: translateY(0.2rem); }
  .hamburger-btn:hover::after {
    transform: translateY(-0.2rem); }

.header {
  background-color: #fbfbfd;
  height: 6rem;
  width: 100%; }
  @media (max-width: 480px) {
    .header {
      height: 5rem; } }
  .header__container {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .header__logo {
    font-weight: 900;
    font-size: 2.6rem;
    color: #37447e;
    transition: all 0.3s; }
    @media (max-width: 480px) {
      .header__logo {
        font-size: 2rem; } }
    .header__logo:hover {
      opacity: 0.7; }
  @media (max-width: 768px) {
    .header__nav {
      display: none; } }
  .header__nav-list {
    display: flex;
    align-items: center; }
  .header__nav-item {
    margin-left: 5rem; }
  .header__nav-link {
    display: inline-block;
    color: #929ecc;
    font-size: 1.4rem;
    line-height: 1.7; }
  .header__nav-btn {
    width: 11rem;
    font-size: 1.2rem; }

.mv {
  position: relative;
  height: 111.2rem;
  padding: 20.6rem 0 0; }
  @media (max-width: 1124px) {
    .mv {
      height: 90rem; } }
  @media (max-width: 768px) {
    .mv {
      height: auto;
      padding: 14rem 0 18rem; } }
  @media (max-width: 480px) {
    .mv {
      padding: 12rem 0; } }
  .mv__cover {
    position: absolute;
    right: 0;
    top: 0;
    width: 102.3rem;
    padding-top: 108.7%;
    display: block;
    pointer-events: none; }
    @media (max-width: 1124px) {
      .mv__cover {
        width: 78rem; } }
    @media (max-width: 768px) {
      .mv__cover {
        height: 100%;
        width: 100%;
        padding-top: 0; } }
  .mv__cover-pc {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%; }
    @media (max-width: 768px) {
      .mv__cover-pc {
        display: none; } }
  .mv__cover-sp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: none; }
    @media (max-width: 768px) {
      .mv__cover-sp {
        display: block;
        width: 100%;
        left: auto;
        right: -28%;
        top: auto;
        bottom: -8%; } }
  .mv__container {
    position: relative; }
  .mv__content {
    max-width: 54rem; }
  .mv__title {
    font-size: 3.6rem;
    font-weight: 500;
    color: #37447e; }
    @media (max-width: 768px) {
      .mv__title {
        font-size: 3rem; } }
    @media (max-width: 768px) {
      .mv__title {
        font-size: 2rem; } }
  .mv__product-name {
    font-size: 7rem;
    display: block;
    margin-top: 1rem; }
    @media (max-width: 768px) {
      .mv__product-name {
        font-size: 5.6rem; } }
    @media (max-width: 480px) {
      .mv__product-name {
        font-size: 4rem;
        margin-top: 0.5rem; } }
  .mv__paragraph {
    margin-top: 1em;
    font-size: 1.8rem;
    color: #505f98;
    line-height: 1.66; }
    @media (max-width: 768px) {
      .mv__paragraph {
        font-size: 1.6rem; } }
    @media (max-width: 480px) {
      .mv__paragraph {
        font-size: 1.4rem; } }
  .mv__btns {
    display: flex;
    margin-top: 13rem; }
    @media (max-width: 768px) {
      .mv__btns {
        flex-direction: column;
        margin-top: 4rem; } }
    @media (max-width: 480px) {
      .mv__btns {
        margin-top: 2rem; } }
  .mv__btn {
    font-size: 1.6rem;
    margin-right: 3rem; }
    @media (max-width: 768px) {
      .mv__btn {
        margin-right: 0;
        margin-top: 2rem; } }
    .mv__btn .btn {
      padding: 0.25em 0;
      width: 19rem; }
      @media (max-width: 480px) {
        .mv__btn .btn {
          width: 15rem; } }

.intro {
  padding-top: 6rem; }
  @media (max-width: 768px) {
    .intro {
      padding-top: 16rem; } }
  @media (max-width: 480px) {
    .intro {
      padding-top: 10rem; } }
  .intro__container {
    text-align: center; }
  .intro__sub {
    color: #6f7cb2;
    font-size: 1.5rem;
    margin-top: 3.2rem;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    @media (max-width: 480px) {
      .intro__sub {
        font-size: 1.4rem;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); } }

.support {
  margin-top: 10.6rem; }
  @media (max-width: 1124px) {
    .support {
      margin-top: 6rem; } }
  @media (max-width: 480px) {
    .support {
      margin-top: 3rem; } }
  .support__container {
    max-width: 144rem;
    padding: 0 5rem;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1124px) {
      .support__container {
        padding: 0 3rem;
        flex-direction: column; } }
    @media (max-width: 768px) {
      .support__container {
        padding: 0 6%; } }
  .support__img {
    width: 47rem;
    flex-shrink: 0;
    margin-right: 5rem; }
    @media (max-width: 1124px) {
      .support__img {
        width: 100%;
        margin-right: 0; } }
    @media (max-width: 768px) {
      .support__img {
        position: relative;
        padding-top: 56%; } }
    @media (max-width: 1124px) {
      .support__img img {
        object-fit: cover;
        object-position: center 30%;
        height: 40rem; } }
    @media (max-width: 768px) {
      .support__img img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%; } }
  .support__content {
    width: 78rem; }
    @media (max-width: 1124px) {
      .support__content {
        width: 100%; } }
  @media (max-width: 1124px) {
    .support__title {
      text-align: center;
      margin-top: 6rem; } }
  .support__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 6.6rem; }
    @media (max-width: 1124px) {
      .support__list {
        justify-content: space-around;
        max-width: 86rem;
        margin: 6.6rem auto 0; } }
    @media (max-width: 768px) {
      .support__list {
        justify-content: space-between;
        margin-top: 3rem; } }
    @media (max-width: 480px) {
      .support__list {
        margin-top: 0; } }
  .support__item {
    width: calc(50% - 2.2rem); }
    .support__item:nth-child(n + 3) {
      margin-top: 4rem; }
    @media (max-width: 480px) {
      .support__item {
        width: 100%;
        margin-top: 4rem; } }
  .support__icon {
    width: 4.8rem; }
    @media (max-width: 480px) {
      .support__icon {
        margin: 0 auto; } }
  .support__sub-title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.66;
    margin-top: 2rem; }
    @media (max-width: 480px) {
      .support__sub-title {
        text-align: center; } }
  .support__item .list {
    margin-top: 0.8rem;
    color: #5d6970; }

.about {
  position: relative;
  padding: 10rem 0 19rem; }
  @media (max-width: 1124px) {
    .about {
      padding: 10rem 0 14rem; } }
  @media (max-width: 480px) {
    .about {
      padding: 6rem 0 8rem; } }
  @media (max-width: 1124px) {
    .about__rows {
      max-width: 86rem;
      margin: 0 auto; } }
  .about__row {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .about__row {
        flex-wrap: wrap;
        max-width: 60rem;
        margin-left: auto;
        margin-right: auto; } }
    .about__row:not(:first-child) {
      margin-top: 14.8rem; }
      @media (max-width: 480px) {
        .about__row:not(:first-child) {
          margin-top: 8rem; } }
  .about__content {
    width: 44.5rem; }
    @media (max-width: 1124px) {
      .about__content {
        width: auto;
        flex-grow: 1;
        margin-left: 3rem; } }
    @media (max-width: 768px) {
      .about__content {
        margin-left: 0;
        margin-top: 2rem; } }
  .about__title {
    line-height: 1.33;
    min-height: 2em; }
    @media (max-width: 768px) {
      .about__title {
        min-height: auto;
        min-height: unset; } }
  .about__paragraph {
    font-size: 1.6rem;
    line-height: 1.625;
    color: #6f7cb2;
    margin-top: 1.8rem; }
    @media (max-width: 480px) {
      .about__paragraph {
        font-size: 1.4rem; } }
    .about__paragraph p:not(:first-child) {
      margin-top: 1em; }
  .about__btn {
    font-size: 1.6rem;
    width: 15rem;
    margin-top: 5rem; }
    @media (max-width: 480px) {
      .about__btn {
        margin-top: 3rem; } }
  .about__img {
    width: 54rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; }
    @media (max-width: 1124px) {
      .about__img {
        width: 40rem;
        margin: 0 auto; } }
    @media (max-width: 480px) {
      .about__img {
        width: 80%; } }
    .about__img::before {
      content: "";
      padding-top: 74.8%;
      display: block; }
    .about__img img {
      max-width: 100%;
      width: auto; }

.fee {
  position: relative;
  background-color: #f4f6ff;
  padding: 15rem 0 19rem; }
  .fee::before {
    content: "";
    position: absolute;
    left: 0;
    top: -3rem;
    width: 100%;
    height: 6rem;
    background-color: #fff;
    border-radius: 50%; }
    @media (max-width: 768px) {
      .fee::before {
        height: 4rem;
        top: -2rem; } }
  @media (max-width: 1124px) {
    .fee {
      padding: 15rem 0 14rem; } }
  @media (max-width: 480px) {
    .fee {
      padding: 10rem 0; } }
  .fee__container {
    max-width: 120rem;
    text-align: center; }
  .fee__title {
    color: #091133;
    text-shadow: none; }
  .fee__text {
    font-size: 3rem;
    margin-top: 6rem; }
    @media (max-width: 768px) {
      .fee__text {
        font-size: 2.4rem; } }
    @media (max-width: 480px) {
      .fee__text {
        font-size: 2rem;
        margin-top: 3rem; } }
  .fee__link {
    color: #6f7cb2; }
    .fee__link.text-link {
      background-image: linear-gradient(90deg, #6f7cb2, #6f7cb2); }
  .fee__price {
    font-size: 5rem;
    font-weight: 500;
    margin-top: 10rem;
    color: #222f65; }
    @media (max-width: 768px) {
      .fee__price {
        font-size: 3.4rem;
        margin-top: 6rem; } }
    @media (max-width: 480px) {
      .fee__price {
        font-size: 2.8rem;
        margin-top: 3rem; } }
  .fee__btn {
    font-size: 1.6rem;
    width: 19rem;
    margin: 10rem auto 0; }
    @media (max-width: 768px) {
      .fee__btn {
        margin-top: 6rem; } }

.footer {
  background-color: #e7ecff; }
  .footer__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .footer__copy-row {
    padding: 6.8rem 0 4.6rem; }
    @media (max-width: 768px) {
      .footer__copy-row {
        padding: 4rem 0 4rem; } }
    @media (max-width: 480px) {
      .footer__copy-row {
        padding: 2rem 0; } }
  .footer__copy {
    color: #939ea4;
    font-weight: 500;
    font-size: 1.4rem; }
    @media (max-width: 480px) {
      .footer__copy {
        font-size: 1.2rem; } }
  .footer__btn {
    width: 11rem;
    font-size: 1.2rem;
    line-height: 1.5; }
  .footer__nav-row {
    border-top: 1px solid #cdd1d4;
    padding: 0 0 2.5rem;
    position: relative; }
    @media (max-width: 480px) {
      .footer__nav-row {
        flex-wrap: wrap;
        padding: 1rem 0 2rem; } }
  .footer__nav-list {
    display: flex;
    flex-wrap: wrap; }
  .footer__nav-item {
    margin-right: 6rem;
    margin-top: 1.3rem; }
    @media (max-width: 768px) {
      .footer__nav-item {
        margin-right: 4rem; } }
    @media (max-width: 480px) {
      .footer__nav-item {
        margin-right: 2.4rem;
        margin-top: 1rem; } }
  .footer__nav-link {
    display: inline-block;
    color: #929ecc;
    font-size: 1.4rem;
    line-height: 1.7; }
    @media (max-width: 480px) {
      .footer__nav-link {
        font-size: 1.2rem; } }
  .footer__sns {
    display: flex;
    align-items: center; }
    @media (max-width: 768px) {
      .footer__sns {
        margin-top: 1rem; } }
    @media (max-width: 480px) {
      .footer__sns {
        margin-top: 0;
        position: absolute;
        right: 0;
        bottom: 2.5rem; } }
  .footer__sns-item {
    margin-left: 3rem; }
    @media (max-width: 480px) {
      .footer__sns-item {
        margin-left: 2rem; } }
  .footer__sns-link {
    display: inline-block;
    width: 1.6rem;
    transition: all 0.3s; }
    .footer__sns-link:hover {
      opacity: 0.7; }

.products-mv {
  position: relative;
  padding: 24rem 0 45.6rem;
  max-width: 144rem;
  margin: 0 auto; }
  @media (max-width: 1124px) {
    .products-mv {
      padding: 14rem 0 60rem; } }
  @media (max-width: 768px) {
    .products-mv {
      padding: 8rem 0 52rem; } }
  @media (max-width: 480px) {
    .products-mv {
      padding: 12rem 0 6rem; } }
  .products-mv__img {
    width: 79.2rem;
    position: absolute;
    right: 0;
    top: 19rem; }
    @media (max-width: 1124px) {
      .products-mv__img {
        width: 75%;
        right: -10rem;
        bottom: 20%;
        top: auto; } }
    @media (max-width: 768px) {
      .products-mv__img {
        width: 85%;
        bottom: 14%;
        right: -8%;
        min-width: 50rem; } }
    @media (max-width: 480px) {
      .products-mv__img {
        position: relative;
        bottom: 0;
        width: 100%;
        margin-right: -10%;
        margin-top: 4rem;
        min-width: 100%; } }
  .products-mv__title {
    position: relative;
    font-size: 7rem;
    font-weight: 500;
    color: #37447e;
    max-width: 48rem;
    line-height: 1;
    z-index: 1; }
    @media (max-width: 768px) {
      .products-mv__title {
        font-size: 5.6rem; } }
    @media (max-width: 480px) {
      .products-mv__title {
        font-size: 4rem;
        max-width: 30rem; } }
  .products-mv__title-sub, .products-mv__title-main {
    display: inline-block;
    background-color: #fff;
    padding: 0.4rem 0.8rem;
    margin-top: 0.4rem;
    line-height: 1.2; }
    @media (max-width: 768px) {
      .products-mv__title-sub, .products-mv__title-main {
        line-height: 1.4; } }
    @media (max-width: 480px) {
      .products-mv__title-sub, .products-mv__title-main {
        margin-top: 0;
        padding: 0; } }
  .products-mv__title-sub {
    display: inline-block;
    font-size: 3.6rem;
    margin-bottom: 6rem;
    line-height: 1; }
    @media (max-width: 1124px) {
      .products-mv__title-sub {
        margin-bottom: 4rem; } }
    @media (max-width: 768px) {
      .products-mv__title-sub {
        font-size: 2rem;
        margin-bottom: 1rem; } }
    @media (max-width: 480px) {
      .products-mv__title-sub {
        margin-bottom: 0; } }
  @media (max-width: 480px) {
    .products-mv__title-main {
      margin-top: 0.5rem; } }

.products-video {
  padding: 0 0 52.8rem; }
  @media (max-width: 768px) {
    .products-video {
      padding: 0 0 14rem; } }
  @media (max-width: 480px) {
    .products-video {
      padding: 0 0 8rem; } }
  .products-video__container {
    max-width: 107.4rem; }
  .products-video__wrap {
    position: relative;
    width: 100%;
    padding-top: 59.76%;
    background-color: #c4c4c4; }

.products-content {
  padding: 0 0 18.6rem; }
  @media (max-width: 768px) {
    .products-content {
      padding: 0 0 14rem; } }
  @media (max-width: 768px) {
    .products-content {
      padding: 0 0 8rem; } }
  .products-content__container {
    max-width: 112rem; }
  .products-content__row {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    @media (max-width: 768px) {
      .products-content__row {
        flex-direction: column; } }
    .products-content__row:not(:first-child) {
      margin-top: 25.5rem; }
      @media (max-width: 768px) {
        .products-content__row:not(:first-child) {
          margin-top: 8rem; } }
  .products-content__img {
    position: relative;
    width: 52.5rem;
    flex-shrink: 0; }
    @media (max-width: 1124px) {
      .products-content__img {
        width: 40rem; } }
    @media (max-width: 768px) {
      .products-content__img {
        width: 100%; } }
    .products-content__img div {
      width: 100%;
      padding-top: 68.5%;
      background-color: #c4c4c4; }
  .products-content__img-text {
    position: absolute;
    left: 0;
    bottom: -10rem;
    color: #6f7cb2;
    font-size: 1.2rem;
    line-height: 2.16;
    max-width: 39rem;
    padding: 2.5rem 0 2.5rem 4.4rem; }
    @media (max-width: 768px) {
      .products-content__img-text {
        position: static;
        padding: 2.5rem 0 0 2rem; } }
    @media (max-width: 480px) {
      .products-content__img-text {
        padding: 0.8rem 0; } }
  .products-content__text {
    max-width: 48rem;
    width: 100%; }
    @media (max-width: 1124px) {
      .products-content__text {
        margin-left: 3rem;
        max-width: 56rem; } }
    @media (max-width: 768px) {
      .products-content__text {
        max-width: 100%;
        margin-left: 0; } }
  .products-content__title {
    font-size: 3.6rem;
    font-weight: 500;
    color: #37447e;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    padding-left: 1.4rem;
    border-left: 6px solid #37447e; }
    @media (max-width: 768px) {
      .products-content__title {
        font-size: 3rem;
        margin-top: 2rem; } }
    @media (max-width: 480px) {
      .products-content__title {
        font-size: 2.4rem;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25); } }
  .products-content__paragraph {
    font-size: 2rem;
    line-height: 1.3;
    color: #6f7cb2;
    margin-top: 8rem; }
    @media (max-width: 1124px) {
      .products-content__paragraph {
        margin-top: 4rem; } }
    @media (max-width: 768px) {
      .products-content__paragraph {
        margin-top: 3rem;
        font-size: 1.8rem; } }
    @media (max-width: 480px) {
      .products-content__paragraph {
        font-size: 1.4rem; } }
    .products-content__paragraph p:not(:first-child) {
      margin-top: 0.6em; }
    .products-content__paragraph p.mt-m {
      margin-top: 2em; }

.sp-only {
  display: none; }
  @media (max-width: 768px) {
    .sp-only {
      display: block; } }

.sp-s-only {
  display: none; }
  @media (max-width: 480px) {
    .sp-s-only {
      display: block; } }

@media (max-width: 768px) {
  .pc-only {
    display: none; } }
